@keyframes fade-in {
  0% {
    background-color: rgba(0 0 0 / 0%);
  }

  100% {
    background-color: rgba(0 0 0 / 50%);
  }
}

.PopupModalContainer {
  position: absolute;
  inset: 0;
  display: flex;
  overflow: hidden;
}

.PopupContainer {
  position: relative;
  display: flex;
  box-sizing: border-box;
  margin: auto;
  max-height: 90%;
  max-width: 90%;
}

.PopupWrapper {
  position: relative;
  overflow: auto;
  max-width: 100%;
}
