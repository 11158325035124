@value colors: "../../cssvariables/colors.css";
@value primary, primaryStrong, primarySoft, primaryHint, secondary, secondaryStrong, secondarySoft, secondaryHint, accent, accentStrong, accentSoft, accentHint from colors;

.NameInput {
  min-width: 50%;
  padding: 3px 5px;
  margin-right: 10%;
  margin-left: 10%;
  border: none;
  background: transparent;
  border-bottom: 1px solid #ccc;
  transition: border 0.5s;
  outline: none;
  font-family: Lato, sans-serif;
  font-size: large;
  text-align: center;
}

.NameInput:hover {
  border-bottom: 1px solid primary;
}

.NameInput:focus {
  border-bottom: 1px solid primary;
}

.SectionTitle {
  font-size: calc(14px + 1.8vmin);
  text-align: left;
  margin-top: 10px;
  padding-left: 12px;
}

.SectionSubTitleContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;
}

.SectionSubTitle {
  position: relative;
  font-size: 16px;
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
}

.DoubleButtonContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 12px;
}

.SectionSubTitleText {
  position: relative;
  z-index: 1;
}
