h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: unset;
}

body {
  position: absolute;
  inset: 0;
  margin: 0;
  overflow: hidden;
}

#root {
  position: relative;
  display: flex;
  height: 100vh;
  height: calc(100vh);
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  width: calc(100vw);
  width: calc(var(--vw, 1vw) * 100);
  box-sizing: border-box;
  scrollbar-width: thin;
  isolation: isolate;
}

a {
  text-decoration: none;
}

button {
  all: unset;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgb(0 0 0 / 5%);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #adabab;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: #adabab;
}
