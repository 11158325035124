@value colors: "../cssvariables/colors.css";
@value primary, primaryStrong, primarySoft, primaryHint, secondary, secondaryStrong, secondarySoft, secondaryHint, accent, accentStrong, accentSoft, accentHint from colors;

@keyframes shake {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(1%);
  }

  50% {
    transform: translateX(-1%);
  }

  75% {
    transform: translateX(1%);
  }

  100% {
    transform: translateX(0%);
  }
}

.CustomTextInput {
  width: 100%;
  border: none;
  margin-top: 15px;
  margin-right: 10px;
  border-bottom: 1px solid accent;
  transition: border 0.3s;
  transition: all 0.5s;
  outline: none;
  background-color: transparent;
  z-index: 2;
  font-size: medium;
}

.Error {
  border-bottom: 1.5px solid red;
  animation: shake 0.6s;
}

.ReadOnly {
  font-style: italic;
  background: rgb(240 240 240);
}

.InputPlaceholder {
  position: absolute;
  bottom: 7px;
  padding: 0 0 0 7px;
  transition: all 0.3s;
  font-size: small;
  opacity: 0.5;
  z-index: 1;
  text-align: left;
  word-wrap: break-word;
  max-width: 100%;
}

.CustomTextInputNotEmpty + .InputPlaceholder,
.CustomTextInput:focus + .InputPlaceholder {
  font-size: 75%;
  transform: translate3d(0, -16px, 0);
  opacity: 1;
  padding-left: 0;
}

.InputContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.InputWrapper {
  position: relative;
  display: flex;
  margin-top: 10px;
}

.FieldInfo {
  text-align: left;
  font-size: smaller;
  margin-top: 2px;
}
