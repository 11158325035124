@value colors: "../../cssvariables/colors.css";
@value primary, primaryStrong, primarySoft, primaryHint, secondary, secondaryStrong, secondarySoft, secondaryHint, accent, accentStrong, accentSoft, accentHint from colors;

.GoogleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285f4;
  font-size: small;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  color: white;
  cursor: pointer;
  transition:
    background-color 0.5s,
    box-shadow 0.5s;
  min-height: 28px;
  margin: 5px;
  padding: 5px 15px;
  border-radius: 50px;
}

.GoogleButton:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
}

.SocialMediaButtonContainer {
  display: flex;
  justify-content: center;
}

.UnividLoginButtonWrapper {
  background-color: #f7a5bf;
  font-size: small;
  min-height: 28px;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition:
    background-color 0.5s,
    box-shadow 0.5s;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  flex-grow: 1;
  margin: 5px;
  padding: 5px 15px;
  border-radius: 50px;
}

.UnividLoginButtonWrapper:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
}

.UnividLoginButton {
  height: 18px;
  width: 18px;
  background-color: white;
  padding: 2px;
  border-radius: 5px;
  user-select: none;
}
