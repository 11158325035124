@value colors: "../cssvariables/colors.css";
@value primary, primaryStrong, primarySoft, primaryHint, secondary, secondaryStrong, secondarySoft, secondaryHint, accent, accentStrong, accentSoft, accentHint from colors;

.LoginContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.LoginWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.LoadingScreen {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 3;
  border-radius: 5px;
}

.CompanyLogoContainer {
  margin: 2vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.CompanyLogo {
  content: url("../img/header_logo_white.png");
  width: 30vmin;
  margin: 4vmin;
  object-fit: cover;
}

.RegisterLoginChoice {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SessionRegistrationBodyHeader {
  font-size: large;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.SwitchModeButtonContainer {
  margin-top: 16px;
  font-size: 14px;
}

.SwitchModeButton {
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  text-decoration: underline;
  transition: box-shadow 0.5s;
  color: rgb(40 40 40);
}

.SwitchModeButtonHint {
  margin-right: 5px;
  color: rgb(60 60 60);
}

.SecondaryButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SecondaryChoiceButton {
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  color: rgb(0 0 0 / 70%);
  font-variant: small-caps;
}

.InputSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex-basis: 50%;
  flex-grow: 1;
  margin-top: 10px;
  gap: 9px;
}

.DescriptionWrapper {
  font-size: large;
  text-align: center;
  margin-bottom: 1vmin;
}

.ParanthesesWrapper {
  font-style: italic;
  color: rgb(0 0 0 / 75%);
  text-align: center;
}

.ResendWrapper {
  background-color: primary;
  color: white;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  font-size: medium;
  margin-top: 15px;
  text-align: center;
}

.InputSubContainer {
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.InputWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.InputPlaceholder {
  position: absolute;
  top: 0;
  padding: 3px 0 0 7px;
  transition: all 0.3s;
  font-size: small;
  opacity: 0.5;
  z-index: 1;
}

.MainChoiceButton {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  text-align: center;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.5s,
    box-shadow 0.5s,
    opacity 0.5s;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  border-radius: 10px;
  color: white;
  padding: 11px;
  margin-top: 13px;
  min-height: 25px;
}

.MainChoiceButtonAvailable {
  background-color: #84dbd7;
  cursor: pointer;
}

.MainChoiceButtonAvailable:hover {
  background-color: #50c6c1;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
}

.MainChoiceButtonDisabled {
  background-color: gray;
  opacity: 0.3;
}

.LoginDivider {
  margin-left: 10px;
  margin-right: 10px;
}

.HorizontalDividerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  width: 100%;
}

.HorizontalDividerWrapper {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HorizontalDivider {
  height: 1px;
  background-color: rgb(0 0 0 / 10%);
  flex-grow: 1;
}

.GoogleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285f4;
  font-size: small;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition:
    background-color 0.5s,
    box-shadow 0.5s;
  min-height: 28px;
  margin: 5px;
}

.GoogleButton:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
}

.LoginAlternativeContainer {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.ContinueWithoutLogInButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition:
    background-color 0.5s,
    box-shadow 0.5s;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  color: grey;
  background-color: white;
  border: 1px solid rgb(0 0 0 / 10%);
  margin-top: 1vmin;
  cursor: pointer;
  min-height: 25px;
  padding: 5px 15px;
  border-radius: 50px;
}

.ContinueWithoutLogInButton:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
}

@keyframes in {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.ErrorContainer {
  animation-name: in;
  animation-duration: 0.4s;
}

.ErrorText {
  text-align: center;
  font-size: medium;
  margin: auto;
  color: rgb(205 27 27);
}

@media (max-width: 800px) {
  .InputContainer {
    flex-direction: column;
  }

  .CompanyLogo {
    width: 40vw;
    margin-bottom: 20px;
  }
}
