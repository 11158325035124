.ExitButton {
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  display: flex;
  color: rgb(40 40 40);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 40%);
  cursor: pointer;
  transform: translate(50%, -50%);
}
