.AppContainer {
  position: absolute;
  inset: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.HeaderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  box-sizing: border-box;
}

.ContentContainer {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}
