.Alert {
  margin-top: 10px;
  text-align: center;
  background-color: #20a310cf;
  color: white;
  width: 90%;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
  transition: opacity 0.5s ease-in-out;
  animation-name: in;
  animation-duration: 0.4s;
}
