.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  cursor: pointer;
  background-color: var(--unividAccentColor);
  border-radius: var(--unividCornerBorderRadius);
  color: white;
  font-style: normal;
  padding: 6px 8px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  gap: 5px;
  transition:
    box-shadow 0.3s,
    border-radius 0.3s,
    background-color 0.3s;
  box-sizing: border-box;
}

.HoverAnimation:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
}

.Button:disabled {
  cursor: default;
  background-color: lightgrey;
  box-shadow: none;
  pointer-events: none;
}

.ButtonInverted {
  background-color: transparent;
  color: var(--unividAccentColor);
  border-width: 2px;
  border-style: solid;
  box-shadow: none;
}

.ButtonInverted:disabled {
  background-color: transparent;
  color: rgb(136 136 136);
  border-color: lightgray;
}

.SmallSize {
  font-size: small;
}

.Fat {
  padding: 10px 20px;
}

.Slim {
  padding: 4px 6px;
}

.FullWidth {
  width: 100%;
}
