.Description {
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
  text-align: left;
}

.Wrapper {
  position: relative;
  word-break: break-word;
  word-wrap: break-word;
  flex-grow: 1;
  max-height: 80px;
  overflow: hidden;
  box-sizing: border-box;
}

.FadeOut {
  position: absolute;
  inset: 50% 0 0;
  background: linear-gradient(
    0deg,
    rgb(255 255 255 / 100%) 0%,
    rgb(255 255 255 / 0%) 100%
  );
  background-size: 100% 50%;
  background-repeat: no-repeat;
  background-position: bottom;
  animation: fade-in-gradient 0.3s forwards ease;
}

.FullDescriptionToggle {
  display: block;
  border: none;
  background: transparent;
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  color: grey;
  font-size: smaller;
}
