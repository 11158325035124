.ErrorBoundary {
  font-family: Lato, sans-serif;
  padding: 20px;
}

.GeneralInfo {
  padding-bottom: 20px;
}

.Heading {
  font-size: x-large;
}

.Subheading {
  font-size: large;
  margin: 0;
}

.Button {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  padding: 10px;
  color: #fff;
  background: rgb(103 164 255);
  cursor: pointer;
}

.Details {
  border-radius: 5px;
  max-width: 500px;
  padding: 10px;
  border: 1px solid lightgray;
  color: gray;
}

.ErrorMessage,
.ComponentStack {
  font-family: "Courier New", Courier, monospace;
}
