.Button {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  font-size: larger;
  padding: 6px 3px;
  border-radius: 12px;
  color: black;
}

.Button:hover {
  background-color: rgb(245 245 245);
}

.Button:disabled {
  cursor: default;
  color: lightgrey;
  box-shadow: none;
  pointer-events: none;
}
