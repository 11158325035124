@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.BackDrop {
  position: absolute;
  inset: 0;
  width: 100%;
  animation: fade-in 0.3s;
}

.BackDropFade {
  background-color: rgb(0 0 0 / 80%);
  animation: fade-in 0.3s;
}

.BackDropBlur {
  backdrop-filter: blur(4px);
}
