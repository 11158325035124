.UnividCard {
  border-radius: var(--unividCornerBorderRadius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(0 0 0 / 0%);
  overflow: auto;
  transition: border 0.3s;
}

.Border {
  padding: 14px;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}

.PaddingSlim {
  padding: 8px;
}

.DefaultWidth {
  width: 80vw;
  max-width: 500px;
}

.Selected {
  border-style: solid;
  border-color: var(--unividAccentColor);
}
