@value colors: "../cssvariables/colors.css";
@value primary, primaryStrong, primarySoft, primaryHint, secondary, secondaryStrong, secondarySoft, secondaryHint, accent, accentStrong, accentSoft, accentHint from colors;

.MainContainer {
  position: absolute;
  inset: 0;
  font-family: Lato, sans-serif;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(180deg, #fff4f7, #fff8f8);
}

.LoginWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  z-index: 1;
}

.LoginCard {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  overflow: auto;
}

.CompanyLogoContainer {
  margin-bottom: 2vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.CompanyLogo {
  content: url("../img/univid-logo-black.png");
  width: 16vmin;
  min-width: 140px;
  object-fit: cover;
}

.LoginContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.LoginPageBackground {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.UserContactInfo {
  text-align: center;
  font-style: italic;
  color: rgb(0 0 0 / 60%);
  font-size: 14px;
  margin-top: 15px;
}
