@keyframes shake {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(1%);
  }

  50% {
    transform: translateX(-1%);
  }

  75% {
    transform: translateX(1%);
  }

  100% {
    transform: translateX(0%);
  }
}

.InputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.InputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.CustomTextInput {
  position: relative;
  border: 1px solid rgb(220 220 220);
  border-bottom: 2px solid rgb(220 220 220);
  border-radius: 5px;
  transition: border 0.3s;
  transition: all 0.5s;
  outline: none;
  font-size: medium;
  padding: 6px 8px;
}

.CustomTextInput::placeholder {
  font-size: small;
}

.Error {
  border-bottom: 1.5px solid red;
  animation: shake 0.6s;
}

.ReadOnly {
  color: #505050;
  background: rgb(240 240 240);
}

.FieldInfo {
  font-size: smaller;
  margin-bottom: 2px;
  font-style: italic;
}
