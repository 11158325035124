.TemplateCardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  margin: 10px;
  gap: 1em;
  margin-left: 1em;
}

.TemplatesWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 1em;
  margin: 0.5em 1em 3em;
}

.SubHeader {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px 0;
}
