.Alert {
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  font-size: 15px;
  border-top: solid;
  padding-top: 5px;
  color: var(--deleteColor);
  animation-name: in;
  animation-duration: 0.4s;
}
