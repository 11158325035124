.ConsentTextContainer {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-size: small;
  color: rgb(0 0 0 / 75%);
}

.ConsentLink {
  color: rgb(0 0 50);
}
