.CookieBanner {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255 247 246);
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
  padding: 14px;
  gap: 14px;
  width: 100vw;
  margin-left: auto;
  text-align: center;
  z-index: 1;
  box-sizing: border-box;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: auto;
  min-width: min(300px, 20vw);
}
